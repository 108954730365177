%form-field {
    border: 1px solid #c6c6c6;
    border-radius: 20px;
    height: 40px;
    color: white;
    padding: 5px 22px;
    width: 100%;
    background-color: #2c3135;
    box-shadow: none !important;
    appearance: none;
    font-size: 18px;
    line-height: normal;
    font-weight: 400;
    text-align: left;
    font-family: inherit;
    @include anim-f(border);

    &:focus,
    &:hover {
        border-color: white;
    }

    &::placeholder {
        color: #999999;
    }
}

.page-zamer {
    &--hero {
        position: relative;
        z-index: 1;
        color: white;

        .breadcrumbs {
            margin-top: 0;
            color: white;
            margin-bottom: 20px;

            @include bp(xl) {
                margin-bottom: 30px;
            }

            &__link {
                color: white;
                font-weight: 400;
            }

            &__item {
                color: white;
            }

            &-body {
                margin: 0;

                @include bp(xl) {
                    padding-top: 0;
                }
            }
        }

        &__bg {
            z-index: -1;
            @include stretch;
            background-position: 50% 50%;
            background-size: cover;
            background-repeat: no-repeat;

            &:before {
                @include pseudos;
                @include stretch;
                background: linear-gradient(50deg, #383d42 10%, rgba(#383d42, 0) 80%),
                    linear-gradient(-50deg, rgba(#da9e12, 0.7) 10%, rgba(#da9e12, 0) 80%) rgba(0, 0, 0, 0.43);
            }
        }

        &__text {
            position: relative;
            padding: 40px 0;

            @include bp(xl) {
                padding-top: 20px;
                min-height: 555px;

                .container {
                    padding-right: 610px;
                }
            }

            h1 {
                font-size: 60px;
                font-weight: 700;
                line-height: 0.9em;
                margin-bottom: 40px;

                @include bp(md) {
                    font-size: 101px;
                }
            }

            ul {
                display: block;

                li {
                    position: relative;
                    margin-bottom: 30px;
                    padding-left: 112px;
                    min-height: 64px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-size: 16px;
                    line-height: 1.4em;
                    font-weight: 400;
                    text-align: left;

                    @include bp(md) {
                        font-size: 20px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    img {
                        @include centre(y);
                        left: 0;
                    }
                }
            }

            p {
                font-size: 18px;
                line-height: 1.56em;
                font-weight: 400;
                padding: 15px 0 15px 15px;
                border-left: 15px solid $color-yellow;

                @include bp(xl){
                    margin-right: 40px;
                }
            }
        }

        &__form {
            background-color: #383d42;
            color: white;
            padding-top: 45px;
            padding-bottom: 25px;

            @include bp(xl) {
                width: 605px;
                padding: 45px 42px 27px;
                border-radius: 5px;
                box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.3);
                position: absolute;
                z-index: 4;
                bottom: -113px;
                left: 50%;
            }

            .container {
                @include bp(md, xl) {
                    max-width: 540px;
                }
            }

            .title {
                display: block;
                position: relative;
                font-size: 22px;
                line-height: normal;
                margin-bottom: 25px;
                color: white;
                font-weight: 400 !important;
                margin-right: 200px;
            }

            .icon {
                display: block;
                margin-bottom: 10px;

                @include bp(md) {
                    position: absolute;
                    top: -20px;
                    right: 0;
                }

                @include bp(xl) {
                    top: -12px !important;
                }
            }
        }
    }

    &--form {
        select {
            display: block !important;
            @extend %form-field;
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAMCAYAAABvEu28AAABQElEQVQ4ja3Ty0pCURTG8b+lk5pWplYqOm3QoHcJkcQLhxPe0ifoZYoKipAIQtK0ToZUaJpYw0rTDCOPl0lSM0W8UOAabb619o+1B1uxvLL6wxhqYhxIBxIFJ2aT8d+XzSYjouDsQpKUQBRcGA36PyNGgx5RcCFJCQAm1Rrd1nulQqXygcthI//4xFetNhJZWlzA5xbZ3tkjncl2IYBSuUy1+onDvk4ul0eW5YGITqvF791gd/+Au1S6k3cggOJbibosY7dZyT7kqNcbPYhmXk3A5+bwKETy5ran1wMBvBaKtFpNbFYL95ksjWYTgLnZGYJ+D6HjE66uk32b9kEAzy8FvtttrJY1Uuk001NTBDe9nIYjxC+lgU9WDkyB89gFKqWSgNeDQgGRaIxoLD5sfDgEED6LolKpOudRpRjXF/kFuIZ19mpMNV0AAAAASUVORK5CYII=");
            background-repeat: no-repeat;
            background-position: calc(100% - 18px) 15px;
            cursor: pointer;

            & ~ .select {
                display: none;
            }
        }

        input[type="text"],
        input[type="tel"],
        input[type="email"],
        input[type="number"],
        input[type="search"],
        input[type="password"],
        textarea {
            @extend %form-field;
        }

        textarea {
            height: 106px;
            border-radius: 10px;
            resize: none;
        }

        label {
            display: block;
            width: 100%;
            position: relative;
            margin-bottom: 15px;

            @include bp(md) {
                margin-bottom: 30px;
            }
        }

        button[type="submit"] {
            display: block;
            width: 100%;
            font-size: 22px;
            font-weight: 700;
            line-height: normal;
            padding: 16px 40px;
            border-radius: 30px;
            background-color: #ca4145;
            @include anim-f(background);
            color: white;
            font-family: inherit;
            cursor: pointer;

            &:hover {
                background-color: #c96c6f;
            }
        }

        input[type="checkbox"] {
            @include hideField;

            &:checked + span:after {
                opacity: 1;
            }

            & + span {
                display: inline-block;
                cursor: pointer;
                padding-left: 35px;
                position: relative;
                font-size: 14px;
                line-height: normal;
                color: #999999;
                font-family: inherit;
                margin-top: 28px;

                a {
                    text-decoration: underline;
                    color: inherit;

                    &:hover {
                        color: white;
                    }
                }

                &:before {
                    @include pseudos;
                    left: 0;
                    top: 0;
                    width: 20px;
                    height: 20px;
                    border-radius: 5px;
                    border: solid 1px #c6c6c6;
                    position: absolute;
                    @include anim-f(border);
                }

                &:after {
                    @include pseudos;
                    width: 10px;
                    height: 5px;
                    transform-origin: 50% 50%;
                    transform: rotate(-45deg);
                    border-style: solid;
                    border-color: #d39b19;
                    border-width: 0 0 1px 1px;
                    top: 6px;
                    left: 5px;
                    position: absolute;
                    opacity: 0;
                    @include anim-f(opacity);
                }

                &:hover,
                &:focus {
                    &:before {
                        border-color: white;
                    }
                }
            }
        }
    }

    &--block-slider {
        padding: 50px 0;

        @include bp(xl) {
            padding: 55px 0 130px;

            .container {
                &:before {
                    @include pseudos;
                    width: 59px;
                    height: 59px;
                    background-color: #d39b19;
                    border-radius: 50%;
                    position: absolute;
                    top: -35px;
                    right: 78px;
                    box-shadow: 0 90px 0 0 #d39b19, 0 180px 0 0 #d39b19;
                }
            }
        }

        h2 {
            font-family: inherit;
            font-weight: 300;
            font-size: 28px;
            line-height: normal;
            margin-bottom: 60px;
            position: relative;
            padding-left: 100px;
            color: #383d42;

            @include bp(md) {
                font-size: 48.5px;
                margin-bottom: 130px;
                max-width: 450px;
                padding-left: 114px;
                line-height: 52px;
            }

            &:after {
                @include pseudos;
                position: absolute;
                top: 5px;
                width: 50vw;
                right: 100%;
                margin-right: -80px;
                height: 100%;
                background-image: repeating-linear-gradient(to bottom, #383d42, #383d42 25px, white 25px, white 38px);

                @include bp(md) {
                    margin-right: -94px;
                    background-image: repeating-linear-gradient(
                        to bottom,
                        #383d42,
                        #383d42 38px,
                        white 38px,
                        white 59px
                    );
                }
            }
        }

        &__container {
            @include bp(xl) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;

                & > * {
                    width: 50%;
                }
            }
        }

        &__slider {
            display: block;
            position: relative;
            @include clearfix;

            @include bp(xl) {
                order: 2;
            }

            .slick-arrow {
                display: block;
                font-size: 0;
                line-height: 0;
                position: absolute;
                background-color: white;
                background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAMCAYAAACwXJejAAAAX0lEQVQokZXOwQ3DMBADwYWRQliCSlDnKuVKUAnrVwwHkJyELz6GuEM1iUmsKldBtfcusIWozjltrW0h71JVJllC7osd/EArqHrwS/469/XxJ3ChJ6D6AkgCwBjj6vec+fUVQjqVH+oAAAAASUVORK5CYII=");
                background-repeat: no-repeat;
                background-size: auto;
                background-position: 50% 50%;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                @include anim-f(border);
                z-index: 3;
                top: -40px;
                right: 0;
                cursor: pointer;
                border: 1px solid white;

                @include bp(md) {
                    right: auto;
                    left: 420px;
                    top: -50px;
                }

                &:hover {
                    border-color: #2c3135;
                }

                &.slick-prev {
                    transform: scaleX(-1);
                    margin-right: 50px;

                    @include bp(md) {
                        margin-left: -60px;
                        margin-right: 0;
                    }
                }
            }

            .slick-list {
                @include bp-to(xl) {
                    overflow: visible !important;
                }

                @include bp(xl) {
                    width: 50vw;
                }
            }

            &_item {
                display: block;
                height: 280px;
                position: relative;
                margin-right: 20px;
                width: 320px;

                @include bp(md) {
                    width: 528px;
                    height: 442px;
                    margin-right: 67px;
                }

                img {
                    @include stretch;
                    object-fit: cover;
                }
            }

            .slick-slide {
                opacity: 0.5;
                @include anim-f(opacity);

                &.slick-current {
                    opacity: 1;
                }
            }
        }

        &__text {
            margin-top: 50px;
            font-size: 18px;
            line-height: 1.56em;
            font-family: inherit;
            text-align: left;
            color: black;
            font-weight: 400;

            @include bp(xl) {
                padding-right: 55px;
                margin-top: 0;
            }

            & > * {
                &:first-child {
                    margin-top: 0 !important;
                }

                &:last-child {
                    margin-bottom: 0 !important;
                }
            }

            p {
                margin: 1.56em 0;
            }
        }
    }

    &--block-consult {
        color: white;
        z-index: 1;
        position: relative;
        padding: 25px 0 60px;
        background-color: #383d42;

        @include bp(xl) {
            padding-bottom: 110px;

            &:before {
                @include pseudos;
                width: 91px;
                position: absolute;
                height: 39px;
                background-color: #da9e12;
                position: absolute;
                right: 0;
                top: -47px;
                box-shadow: 0 59px 0 0 #da9e12, 18.5px 32px 43px 0 rgba(4, 4, 4, 0.37),
                    18.5px 91px 43px 0 rgba(4, 4, 4, 0.37);
            }
        }

        &__bg {
            @include stretch;
            background-position: 50% 50%;
            background-size: cover;
            background-repeat: no-repeat;
            mix-blend-mode: luminosity;
            opacity: 0.1;
        }

        h2 {
            font-size: 28px;
            line-height: normal;
            font-family: inherit;
            font-weight: 300;
            position: relative;
            display: block;
            color: #383d42;
            z-index: 2;
            margin-bottom: 100px;

            @include bp(md, xl) {
                max-width: 540px;
                margin-left: 37px;
            }

            @include bp(xl) {
                font-size: 48.5px;
                max-width: 850px;
                margin-left: 37px;
                margin-bottom: 150px;
            }

            &:before {
                @include pseudos;
                width: 120vw;
                top: em(-76, 48.5);
                bottom: em(-76, 48.5);
                right: em(-74, 48.5);
                z-index: -1;
                position: absolute;
                background-color: #da9e12;
            }
        }

        ul {
            margin-bottom: -40px;

            @include bp(md) {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
            }

            li {
                list-style: none;
                position: relative;
                text-align: center;
                font-size: 18px;
                font-weight: 40;
                font-family: inherit;
                line-height: 1.56em;
                margin-bottom: 40px;

                @include bp(md, xl) {
                    width: calc(50% - 20px);
                    margin-right: 40px;

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }

                @include bp(xl) {
                    width: calc(25% - 15px);
                    margin-right: 20px;

                    &:nth-child(4n) {
                        margin-right: 0;
                    }
                }

                &:before {
                    @include pseudos;
                    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA2CAYAAACbZ/oUAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hJREFUeNrsmmuITGEYx98x2KSESNpoE7XkshSLDWndQ+SWD7uRL2zCJlaUuyyRZRNfzAekrGyRyJ3cFlsuUXY/0Bal7Ic17mT5P83/tKdp53LOnDNz3pl56te8s/O+c+a/z3ue932e9/gaAz1Vhtg60LdjhohdDg5Jo0MGiN0IAmwvEw+vBkWgkwd/rA/8BSfAVRvjy8E+tpeAGhFcrYGXvtgQvBnsYbtUxErDuIf/gdnglweneSt4aHHMDrCV7engmvGBWfDlNLlnd4MtbC80izULFsuhh3W2w2AN25PBnfAO6RSlK01ip7YnNtzDOluAa63YWPA4Usd0EHzcJHYMeBqts+6Cz4Cl4AeYCOpjDdBZ8EmKlUA7EjTEM0jXoFULSkAzp3FDvAN19PBFMAd8BQXgg5XBunn4EsW+B6OtitXNw7e4mfgIhoEWO1+ii4fvUmwTPdti94u8LrgzqOOS8w4M53Q2m6S1g9JF8H1QCN6AUSDYTp+1oBHM01lwd/Cc0/cFxUaaxrl8Hapr0JJp/AjkU6yI/hOl/ze+BhPxsJRWFoGBSRbbmyLzmfSPiyHWskXycAFLIvJf6wN+JmkaPwF54B4DleMWycNvWSnoxunlc1lsLj2bx/V2klsXiiT4swrVgl7S226Wf/oxQPUH10GxCpWckirYsCncoM8AR10U2wtcAdPcvm9iCf7E1EsCRxmocPDaQ8ArIGc958CsZETFeNZh2dnMZLuSOWiiJtH/GWPEebA4WctAvBuPm2CBqcowIYFrFjI2yHp7SoVKqcprgo2kexfbt8EAG9cbzL1xFxUqvJUme1djdWsp1fwq4OcP72phbDEDlJgU3lakYhtnZy9dzikuu6IHFqbxDU7jI2BVqvatdpMHWT6kHDqCy0msvnVsVzO7UboJbmXgCnKNPhahn5wAGKd+O1XbyYB2gsWkPDqe++yVYFPY53NV20HWfrDNC6lYovnwa9MStZcZlth8cIHtCoc3LCkVLFZvirg19GYt32/ne8+YUwUAWVN7gANgA/9WFuXe1trDhh0Ep01Ll+fEOi1YrIQ5bZXyqLlRxGtSHrZMeE4rKzgrOCs4KzgrOCtYB8G6PnbYbDV5kCq/HKVIqfS3cv9YxUmTZ6mL2PbHK9gQeFbz2ZoTr+D1KnR+5NfMu4ZJuek7c/GY9l+AAQDn6Jwo7LrhkwAAAABJRU5ErkJggg==");
                    background-position: 0 0;
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 60px;
                    height: 54px;
                    margin: 0 auto 35px;
                }
            }
        }
    }

    &--block-steps {
        font-size: 18px;
        line-height: 1.56em;
        font-family: inherit;
        font-style: normal;
        font-weight: 400;
        padding: 40px 0;
        color: #383d42;

        @include bp(xl) {
            padding: 60px 0;
        }

        ul {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            margin-bottom: -40px;

            @include bp(xl) {
                flex-wrap: nowrap;
            }

            li {
                width: 100%;
                margin-bottom: 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;
                min-height: 107px;

                @include bp(md, xl) {
                    width: calc(50% - 20px);
                    margin-right: 40px;

                    &:nth-child(2n) {
                        margin-right: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                @include bp(xl) {
                    width: auto;
                    margin-right: 50px;

                    &:nth-child(3n) {
                        margin-right: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &:before {
                    @include pseudos;
                    width: 15px;
                    height: 107px;
                    background-color: #383d42;
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                &:first-child {
                    padding-left: 38px;
                }

                &:nth-child(2) {
                    padding-left: 62px;

                    &:before {
                        box-shadow: 24px 0 0 0 #383d42;
                    }
                }

                &:nth-child(3) {
                    padding-left: 85px;

                    &:before {
                        box-shadow: 24px 0 0 0 #383d42, 48px 0 0 0 #383d42;
                    }
                }
            }
        }

        p {
            margin: 1.56em 0;
        }

        &__text {
            margin-top: 50px;

            @include bp(xl) {
                margin-top: 75px;
            }

            @include bp(lg) {
                columns: 2;
                column-gap: 107px;

                & > *:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}
