.page-designer {
    &--hero {
        .page-zamer--hero__bg {
            &:before {
                background: linear-gradient(50deg, #383d42 10%, rgba(56, 61, 66, 0) 80%) rgba(0, 0, 0, 0.43);
            }
        }
    }

    &--steps {
        padding: 60px 0;

        @include bp(xl) {
            padding: 130px 0;
            position: relative;

            &:before {
                @include pseudos;
                width: 40%;
                border-top: 30px dotted $color-yellow;
                top: 65px;
                left: 0;
                position: absolute;
            }
        }

        h2 {
            font-size: 32px;
            font-weight: 300;
            line-height: normal;
            margin-bottom: 0.8em;

            @include bp(xl) {
                font-size: 48px;
                margin-left: 15px;
            }
        }

        ul {
            list-style: none;
            margin-bottom: -40px;

            @include bp(xl) {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
            }

            li {
                font-size: 16px;
                line-height: 1.75em;
                font-weight: normal;
                letter-spacing: normal;
                color: black;
                margin-bottom: 40px;

                @include bp(xl) {
                    width: 30%;
                    margin-right: 5%;

                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                }

                span {
                    display: flex;
                    margin-bottom: 20px;
                    min-height: 107px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: stretch;
                    position: relative;
                    padding-left: 37px;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 1.4em;

                    @include bp(md) {
                        font-size: 20px;
                    }

                    &:before {
                        @include pseudos;
                        width: 15px;
                        height: 100px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        background-color: $color-yellow;
                    }
                }

                &:nth-child(2) {
                    span {
                        padding-left: 61px;

                        &:before {
                            box-shadow: 24px 0 0 0 $color-yellow;
                        }
                    }
                }

                &:nth-child(3) {
                    span {
                        padding-left: 85px;

                        &:before {
                            box-shadow: 24px 0 0 0 $color-yellow, 48px 0 0 0 $color-yellow;
                        }
                    }
                }
            }
        }
    }

    &--features{
        color: white;
        z-index: 1;
        position: relative;
        padding: 0 0 60px;
        background-color: #383d42;

        @include bp(xl) {
            padding-bottom: 100px;
        }

        p{
            text-align: center;
            font-size: 20px;
            line-height: normal;
            font-weight: 300;
            margin-bottom: 60px;
        }

        &__bg {
            @include stretch;
            background-position: 50% 50%;
            background-size: cover;
            background-repeat: no-repeat;
            mix-blend-mode: luminosity;
            opacity: 0.1;
        }

        h2 {
            font-size: 22px;
            line-height: normal;
            font-family: inherit;
            font-weight: 300;
            position: relative;
            display: block;
            color: white;
            z-index: 2;
            background-color: $color-yellow;
            width: 100vw;
            position: relative;
            left: 50%;
            margin-left: -50vw;
            padding: 1.3em 30px;
            border-left: 15px solid #feb200;
            margin-bottom: 30px;

            @include bp(md){
                width: 100%;
                left: auto;
                margin-left: auto;
                margin-right: auto;
                max-width: 700px;
                padding: 1.3em 20px;
                font-size: 30px;
                transform: translateY(-33px);
            }
        }

        ul {
            margin-bottom: -40px;

            @include bp(md) {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
            }

            li {
                list-style: none;
                position: relative;
                text-align: center;
                font-size: 18px;
                font-weight: 40;
                font-family: inherit;
                line-height: 1.56em;
                margin-bottom: 40px;

                @include bp(md, xl) {
                    width: calc(50% - 20px);
                    margin-right: 40px;

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }

                @include bp(xl) {
                    width: calc(25% - 15px);
                    margin-right: 20px;

                    &:nth-child(4n) {
                        margin-right: 0;
                    }
                }

                &:before {
                    @include pseudos;
                    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA2CAYAAACbZ/oUAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hJREFUeNrsmmuITGEYx98x2KSESNpoE7XkshSLDWndQ+SWD7uRL2zCJlaUuyyRZRNfzAekrGyRyJ3cFlsuUXY/0Bal7Ic17mT5P83/tKdp53LOnDNz3pl56te8s/O+c+a/z3ue932e9/gaAz1Vhtg60LdjhohdDg5Jo0MGiN0IAmwvEw+vBkWgkwd/rA/8BSfAVRvjy8E+tpeAGhFcrYGXvtgQvBnsYbtUxErDuIf/gdnglweneSt4aHHMDrCV7engmvGBWfDlNLlnd4MtbC80izULFsuhh3W2w2AN25PBnfAO6RSlK01ip7YnNtzDOluAa63YWPA4Usd0EHzcJHYMeBqts+6Cz4Cl4AeYCOpjDdBZ8EmKlUA7EjTEM0jXoFULSkAzp3FDvAN19PBFMAd8BQXgg5XBunn4EsW+B6OtitXNw7e4mfgIhoEWO1+ii4fvUmwTPdti94u8LrgzqOOS8w4M53Q2m6S1g9JF8H1QCN6AUSDYTp+1oBHM01lwd/Cc0/cFxUaaxrl8Hapr0JJp/AjkU6yI/hOl/ze+BhPxsJRWFoGBSRbbmyLzmfSPiyHWskXycAFLIvJf6wN+JmkaPwF54B4DleMWycNvWSnoxunlc1lsLj2bx/V2klsXiiT4swrVgl7S226Wf/oxQPUH10GxCpWckirYsCncoM8AR10U2wtcAdPcvm9iCf7E1EsCRxmocPDaQ8ArIGc958CsZETFeNZh2dnMZLuSOWiiJtH/GWPEebA4WctAvBuPm2CBqcowIYFrFjI2yHp7SoVKqcprgo2kexfbt8EAG9cbzL1xFxUqvJUme1djdWsp1fwq4OcP72phbDEDlJgU3lakYhtnZy9dzikuu6IHFqbxDU7jI2BVqvatdpMHWT6kHDqCy0msvnVsVzO7UboJbmXgCnKNPhahn5wAGKd+O1XbyYB2gsWkPDqe++yVYFPY53NV20HWfrDNC6lYovnwa9MStZcZlth8cIHtCoc3LCkVLFZvirg19GYt32/ne8+YUwUAWVN7gANgA/9WFuXe1trDhh0Ep01Ll+fEOi1YrIQ5bZXyqLlRxGtSHrZMeE4rKzgrOCs4KzgrOCtYB8G6PnbYbDV5kCq/HKVIqfS3cv9YxUmTZ6mL2PbHK9gQeFbz2ZoTr+D1KnR+5NfMu4ZJuek7c/GY9l+AAQDn6Jwo7LrhkwAAAABJRU5ErkJggg==");
                    background-position: 0 0;
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 60px;
                    height: 54px;
                    margin: 0 auto 35px;
                }
            }
        }
    }

    &--slider{
        padding: 60px 0;

        @include bp(xl){
            padding: 105px 0;

            .container:before{
                display: none;
            }
        }

        .highlight{
            margin-top: 40px;
            padding-left: 67px;
            position: relative;

            @include bp(xl){
                margin-top: 60px;
            }

            &:before{
                @include pseudos;
                width: 50vw;
                height: 40px;
                background-color: #383d42;
                position: absolute;
                right: 100%;
                margin-right: -50px;
                top: 7px;
                box-shadow: 0 60px 0 0 #383d42;
            }
        }

        .page-zamer--block-slider__slider{
            .slick-list{
                padding-bottom: 10px;
            }

            .slick-slide{
                box-shadow: 10px 10px 0 0 rgba($color-yellow, 0);
                transition: all .2s ease;

                &.slick-current{
                    box-shadow: 10px 10px 0 0 $color-yellow;
                }
            }
        }
    }
}
